angular.module("adminApp")
    .factory('viewAdminUserModal', ["$uibModal", function ($uibModal) {
        function open(userProfile) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/admin/modals/viewAdminUserModal.html",
                controller: "ViewAdminUserModalCtrl",
                resolve: {
                    userProfile: function () {
                        return userProfile;
                    }
                }
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("ViewAdminUserModalCtrl", ["$scope", "$uibModalInstance", "userProfile", "aerosAdminApi", "Notification", function ($scope, $uibModalInstance, userProfile, aerosAdminApi, Notification) {
        $scope.user = userProfile.user;
        $scope.roles = userProfile.roles;

        $scope.updateUser = function () {
            aerosAdminApi.updateAdminUserProfile($scope.user)
                .success(function () {
                    $uibModalInstance.close();
                    Notification.success("User successfully updated");
                })
                .error(function (err) {
                    Notification.error("Something went wrong. User not updated. " + err.message);
                });
        }
    }]);